<script setup>
import { ref, reactive, onMounted, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useAuthStore } from '@/store/auth'
import Password from 'primevue/password'

const router = useRouter()
const auth = useAuthStore()
const toast = useToast()
const ability = inject('ability')
let submitted = ref(false)
let isLoading = ref(false)

const form = reactive({
  login: '',
  password: '',
  token_device: '',
})

const appName = process.env.VUE_APP_APP_NAME
const appVersion = process.env.VUE_APP_VERSION

const rules = {
  login: {
    required: helpers.withMessage(
      'Email/Username/No. Handphone harus diisi.',
      required
    ),
  },
  password: {
    required: helpers.withMessage('Password harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

onMounted(() => {})

watch(auth, (val) => {
  if (val.isAuthenticated) {
    router.push({ name: 'Home' })
  }
})

function login(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  isLoading.value = true
  auth
    .login(form.login, form.password, ability)
    .then(() => {
      router.push({ name: 'Home' })
    })
    .catch((err) => {
      errorHandler(err)
    })
    .finally(() => (isLoading.value = false))
}

function errorHandler(error) {
  const title = 'Login'
  const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message

  if (!error.response || !error.response.status) {
    toast.add({
      severity: 'error',
      summary: title,
      detail: 'Gagal terhubung ke server. Harap cek koneksi internet Anda.',
      life: 3000,
    })

    return
  }

  switch (error.response.status) {
    case 400:
      toast.add({
        severity: 'error',
        summary: title,
        detail: message,
        life: 3000,
      })
      break
    case 422:
      if (error.response.data.data) {
        const data = error.response.data.data
        let msg = ''
        for (const [, value] of Object.entries(data)) {
          msg = msg + value
        }
        toast.add({
          severity: 'error',
          summary: title,
          detail: msg,
          life: 3000,
        })
      }
      break
    case 429:
      toast.add({
        severity: 'error',
        summary: title,
        detail: 'Too many request, please try again later.',
        life: 3000,
      })
      break
    default:
      toast.add({
        severity: 'error',
        summary: title,
        detail: message,
        life: 3000,
      })
      break
  }
}
</script>

<template>
  <div
    class="flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <Card style="width: 25rem">
      <template #title>
        <div class="text-center m-2">
          <img src="img/logo.png" :alt="appName" height="100" />
        </div>
      </template>
      <template #content>
        <form @submit.prevent="login(!v$.$invalid)">
          <div class="formgrid grid">
            <div class="field col-12">
              <label
                for="login"
                :class="{ 'p-error': v$.login.$invalid && submitted }"
                >Email/Username/No. Handphone
              </label>
              <InputText
                v-model="v$.login.$model"
                type="text"
                :class="{ 'p-invalid': v$.login.$invalid && submitted }"
                class="inputfield w-full"
                autocomplete="username"
              />
              <small
                v-if="
                  (v$.login.$invalid && submitted) ||
                  v$.login.$pending.$response
                "
                class="p-error"
                >{{ v$.login.required.$message }}</small
              >
            </div>
            <div class="field col-12">
              <div class="flex justify-content-between mb-1">
                <label
                  for="password"
                  :class="{ 'p-error': v$.password.$invalid && submitted }"
                  >Password</label
                >
                <div class="text-sm">Lupa password?</div>
              </div>
              <password
                v-model="v$.password.$model"
                :feedback="false"
                :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                autocomplete="current-password"
                class="w-full"
                input-class="w-full"
                toggle-mask
              />
              <small
                v-if="
                  (v$.password.$invalid && submitted) ||
                  v$.password.$pending.$response
                "
                class="p-error"
                >{{ v$.password.required.$message }}</small
              >
            </div>
            <div class="field col-12">
              <Button
                label="Login"
                :loading="isLoading"
                class="w-full"
                type="submit"
              />
            </div>
          </div>
        </form>
        <div class="text-center text-xs text-surface-300">
          <span class="uppercase"> {{ appName }} DEMO</span> - v{{ appVersion }}
        </div>
      </template>
    </Card>
  </div>
</template>
