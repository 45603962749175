<template>
  <div>
    <div class="mb-4 flex align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Home </span>
    </div>
    <div class="msg">
      <div style="text-align: center">
        <Message>VERSI DEMO</Message>
      </div>
    </div>

    <div class="grid">
      <div class="col-12 md:col-12">
        <div class="grid">
          <div class="col-12 md:col-9">
            <div class="text-lg font-bold">Overview</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-12 md:col-2">
            <div
              class="card card-content p-2 mb-2"
              style="background-color: var(--blue-100)"
            >
              <div class="text-center">
                <div class="text-md font-bold">10</div>
              </div>
            </div>
            <div class="block text-center title text-xs text-500">Anggota</div>
          </div>
          <div class="col-12 md:col-2">
            <div
              class="card card-content p-2 mb-2"
              style="background-color: var(--blue-100)"
            >
              <div class="text-center">
                <div class="text-md font-bold">32</div>
              </div>
            </div>
            <div class="block text-center title text-xs text-500">Simpanan</div>
          </div>
          <div class="col-12 md:col-2">
            <div
              class="card card-content p-2 mb-2"
              style="background-color: var(--blue-100)"
            >
              <div class="text-center">
                <div class="text-md font-bold">12</div>
              </div>
            </div>
            <div class="block text-center title text-xs text-500">
              Penarikan Simpanan
            </div>
          </div>
          <div class="col-12 md:col-2">
            <div
              class="card card-content p-2 mb-2"
              style="background-color: var(--blue-100)"
            >
              <div class="text-center">
                <div class="text-md font-bold">5</div>
              </div>
            </div>
            <div class="block text-center title text-xs text-500">Pinjaman</div>
          </div>
          <div class="col-12 md:col-2">
            <div
              class="card card-content p-2 mb-2"
              style="background-color: var(--blue-100)"
            >
              <div class="text-center">
                <div class="text-md font-bold">3</div>
              </div>
            </div>
            <div class="block text-center title text-xs text-500">
              Pinjaman Jatuh Tempo
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <div class="card">
          <Chart type="line" :data="chartData" :options="chartOptions" />
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="card bg-white">
          <div class="block text-xl">Pinjaman (Jatuh Tempo)</div>
          <div
            class="border-round overflow-hidden w-3rem mt-3"
            style="height: 3px"
          />
          <DataTable
            :value="pinjaman_jatuh_tempo"
            class="p-datatable-sm"
            paginator
            :rows="5"
            :rowsPerPageOptions="[5, 10, 20, 50]"
          >
            <Column field="anggota" header="Anggota"></Column>
            <Column field="tanggal" header="Tanggal"
              ><template #body="{ data }">
                {{ formatDate(data.tanggal) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { formatCurrency } from '@/helpers'

// import { ABILITY_TOKEN } from '@casl/vue'

export default {
  name: 'Home',
  /* inject: {
    $ability: { from: ABILITY_TOKEN },
  }, */
  data() {
    return {
      chartData: null,
      chartOptions: null,
      pinjaman_jatuh_tempo: [
        { anggota: 'Bayu Aji Prakoso', tanggal: '2029/01/01' },
        { anggota: 'Dhimas Arfian Prabantoro', tanggal: '2029/02/01' },
        { anggota: 'Khalis Anggoro Putro', tanggal: '2029/03/01' },
        { anggota: 'Sri Mulyani', tanggal: '2029/04/01' },
      ],
    }
  },
  mounted() {
    this.chartData = this.setChartData()
    this.chartOptions = this.setChartOptions()
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return dayjs(value).format('DD/MM/YYYY')
    },
    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement)
      return {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Simpanan',
            backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56],
            borderWidth: 1,
          },
          {
            label: 'Penarikan Simpanan',
            backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
            borderColor: documentStyle.getPropertyValue('--yellow-500'),
            data: [30, 25, 60, 70, 56, 77, 40, 40, 30, 30, 61, 46],
            borderWidth: 1,
          },
          {
            label: 'Pinjaman',
            backgroundColor: documentStyle.getPropertyValue('--indigo-500'),
            borderColor: documentStyle.getPropertyValue('--indigo-500'),
            data: [35, 49, 60, 51, 36, 45, 50, 65, 59, 60, 61, 36],
            borderWidth: 1,
          },
        ],
      }
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement)
      const textColor = documentStyle.getPropertyValue('--text-color')
      const textColorSecondary = documentStyle.getPropertyValue(
        '--text-color-secondary'
      )
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border')

      return {
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      }
    },
  },
}
</script>

<style scoped>
.msg {
  padding: 0px;
}
</style>
