import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import EmptyLayout from '../layouts/Empty.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('@/views/404.vue'),
    meta: { title: 'Halaman tidak ditemukan !' },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true, title: 'Beranda' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login', layout: EmptyLayout },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout.vue'),
    meta: { auth: true, layout: EmptyLayout },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true, title: 'Beranda' },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/master/Setting.vue'),
    meta: { auth: true, title: 'Setting Aplikasi' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: { auth: true, title: 'User profile' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: { auth: true, title: 'User profile' },
  },
  {
    path: '/anggota',
    name: 'Anggota',
    component: () => import('@/views/anggota/Anggota.vue'),
    meta: { auth: true, title: 'Anggota' },
  },
  {
    path: '/simpanan',
    name: 'Simpanan',
    component: () => import('@/views/simpanan/Simpanan.vue'),
    meta: { auth: true, title: 'Simpanan' },
  },
  {
    path: '/simpanan/add',
    name: 'SimpananAdd',
    component: () => import('@/views/simpanan/SetSimpanan.vue'),
    meta: { auth: true, title: 'Simpanan' },
  },
  {
    path: '/simpanan/edit/:id',
    name: 'SimpananEdit',
    component: () => import('@/views/simpanan/SetSimpanan.vue'),
    meta: { auth: true, title: 'Simpanan' },
  },
  {
    path: '/penarikan',
    name: 'Penarikan',
    component: () => import('@/views/simpanan/Penarikan.vue'),
    meta: { auth: true, title: 'Penarikan' },
  },
  {
    path: '/penarikan/add',
    name: 'PenarikanAdd',
    component: () => import('@/views/simpanan/SetPenarikan.vue'),
    meta: { auth: true, title: 'Penarikan' },
  },
  {
    path: '/penarikan/edit/:id',
    name: 'PenarikanEdit',
    component: () => import('@/views/simpanan/SetPenarikan.vue'),
    meta: { auth: true, title: 'Penarikan' },
  },
  {
    path: '/pinjaman',
    name: 'Pinjaman',
    component: () => import('@/views/pinjaman/Pinjaman.vue'),
    meta: { auth: true, title: 'Pinjaman' },
  },
  {
    path: '/pinjaman/add',
    name: 'PinjamanAdd',
    component: () => import('@/views/pinjaman/SetPinjaman.vue'),
    meta: { auth: true, title: 'Pinjaman' },
  },
  {
    path: '/pinjaman/edit/:id',
    name: 'PinjamanEdit',
    component: () => import('@/views/pinjaman/SetPinjaman.vue'),
    meta: { auth: true, title: 'Pinjaman' },
  },
  {
    path: '/master/identitas-perusahaan',
    name: 'IdentitasPerusahaan',
    component: () => import('@/views/master/IdentitasPerusahaan.vue'),
    meta: { auth: true, title: 'Identitas Perusahaan' },
  },
  {
    path: '/master/jenis-simpanan',
    name: 'JenisSimpanan',
    component: () => import('@/views/master/Jsim.vue'),
    meta: { auth: true, title: 'Jenis Simpanan' },
  },
  {
    path: '/master/jenis-pinjaman',
    name: 'JenisPinjaman',
    component: () => import('@/views/master/Jpin.vue'),
    meta: { auth: true, title: 'Jenis Pinjaman' },
  },
  {
    path: '/master/jangka',
    name: 'Jangka',
    component: () => import('@/views/master/Jangka.vue'),
    meta: { auth: true, title: 'Jangka Pinjaman' },
  },
  {
    path: '/master/jenis-penerimaan',
    name: 'JenisPenerimaan',
    component: () => import('@/views/master/JenisPenerimaan.vue'),
    meta: { auth: true, title: 'Jenis Penerimaan' },
  },
  {
    path: '/master/jenis-pengeluaran',
    name: 'JenisPengeluaran',
    component: () => import('@/views/master/JenisPengeluaran.vue'),
    meta: { auth: true, title: 'Jenis Pengeluaran' },
  },
  {
    path: '/master/user',
    name: 'User',
    component: () => import('@/views/master/User.vue'),
    meta: { auth: true, title: 'User' },
  },
  {
    path: '/master/role-permission',
    name: 'Role & Permission',
    component: () => import('@/views/master/RolePermission.vue'),
    meta: { auth: true, title: 'Role & Permission' },
  },
  {
    path: '/keuangan/head-akun',
    name: 'HeadAkun',
    component: () => import('@/views/keuangan/HeadAkun.vue'),
    meta: { auth: true, title: 'Head Akun' },
  },
  {
    path: '/keuangan/kelompok-akun',
    name: 'KelompokAkun',
    component: () => import('@/views/keuangan/KelompokAkun.vue'),
    meta: { auth: true, title: 'Kelompok Akun' },
  },
  {
    path: '/keuangan/perkiraan-akun',
    name: 'PerkiraanAkun',
    component: () => import('@/views/keuangan/PerkiraanAkun.vue'),
    meta: { auth: true, title: 'Perkiraan Akun' },
  },
  {
    path: '/keuangan/penerimaan',
    name: 'PenerimaanKas',
    component: () => import('@/views/keuangan/Penerimaan.vue'),
    meta: { auth: true, title: 'Penerimaan Kas' },
  },
  {
    path: '/keuangan/pengeluaran',
    name: 'PengeluaranKas',
    component: () => import('@/views/keuangan/Pengeluaran.vue'),
    meta: { auth: true, title: 'Pengeluaran Kas' },
  },
  {
    path: '/keuangan/jurnal-umum',
    name: 'JurnalUmum',
    component: () => import('@/views/keuangan/jurnal-umum/JurnalUmum.vue'),
    meta: { auth: true, title: 'Jurnal Umum' },
  },
  {
    path: '/keuangan/jurnal-umum/add',
    name: 'JurnalUmumAdd',
    component: () => import('@/views/keuangan/jurnal-umum/SetJurnalUmum.vue'),
    meta: { auth: true, title: 'Jurnal Umum' },
  },
  {
    path: '/simpanan/edit/:id',
    name: 'JurnalUmumEdit',
    component: () => import('@/views/keuangan/jurnal-umum/SetJurnalUmum.vue'),
    meta: { auth: true, title: 'Jurnal Umum' },
  },
  {
    path: '/keuangan/neraca-saldo',
    name: 'NeracaSaldo',
    component: () => import('@/views/keuangan/NeracaSaldo.vue'),
    meta: { auth: true, title: 'Neraca Saldo' },
  },
  {
    path: '/keuangan/laba-rugi',
    name: 'LabaRugi',
    component: () => import('@/views/keuangan/LabaRugi.vue'),
    meta: { auth: true, title: 'Laba Rugi' },
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: () => import('@/views/laporan/Laporan.vue'),
    meta: { auth: true, title: 'Pengeluaran Kas' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  window.document.title = to.meta.title + ' - ' + process.env.VUE_APP_APP_NAME

  if (to.matched.some((item) => item.meta.auth)) {
    if (localStorage.getItem('aid')) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.path) {
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
